import moment from 'moment';

export const tpDateParser = (date: string) => {
  return new Intl.DateTimeFormat('en-GB')
    .format(new Date(date))
    .split('/')
    .reverse()
    .join('-');
};

/**
 *
 * @param str date string input
 * @returns date format D MMM, yyyy
 */
export const dateStringToFriendlyDate = (str: string) => {
  return moment(str).format('D MMM, yyyy');
};

/**
 *
 * @param str date string input
 * @returns date format D MMM, yyyy
 */
export const dateStringToFriendlyDateUTC = (str: string) => {
  return moment(str.substring(0, 10)).format('D MMM, yyyy');
};

/**
 *
 * @param str date string input
 * @returns date format mm/dd/yyyy
 */
export const dateToInputDateFormat = (date: Date) => {
  return moment(date.toISOString()).format('MM/DD/yyyy');
};

/**
 *
 * @param str date format mm/dd/yyyy
 */
export const dateStringToDateFormat = (str: string) => {
  const [month, day, year] = str.split('/');

  return new Date(+year, +month - 1, +day).toISOString();
};

export const getInternationalDateTz = (date: string) => {
  return date
    ? new Intl.DateTimeFormat('en-US', { timeZone: 'America/New_York' }).format(
        Date.parse(date)
      )
    : '';
};

export const getInternationalYear = (date: string) => {
  return date
    ? new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
      }).format(Date.parse(date))
    : '';
};

export const getInternationalDMY = (date: string, timeZone?: string) => {
  return date
    ? new Intl.DateTimeFormat('en-US', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        timeZone,
      }).format(Date.parse(date))
    : '';
};

export const getInternationalFullDate = (
  date: string,
  year: any = 'numeric'
) => {
  return date
    ? new Intl.DateTimeFormat('en-US', {
        day: '2-digit',
        month: 'short',
        weekday: 'short',
        year,
        timeZone: 'America/New_York',
      }).format(Date.parse(date))
    : '';
};

export const getInternationalTime = (
  date: string,
  hasTimezone: boolean = false
) => {
  return date
    ? new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        timeZone: hasTimezone ? 'America/New_York' : 'UTC',
      }).format(Date.parse(date))
    : '';
};

export const getInternationalWeekDateTz = (
  date: string,
  hasWeekday: boolean = true
) => {
  return date
    ? new Intl.DateTimeFormat('en-US', {
        day: '2-digit',
        month: 'short',
        weekday: hasWeekday ? 'short' : undefined,
        timeZone: 'America/New_York',
      }).format(Date.parse(date))
    : '';
};

export const getInternationalTimeShort = (date: string) => {
  return date
    ? new Intl.DateTimeFormat('en-US', {
        day: '2-digit',
        month: 'short',
        weekday: 'short',
        timeZone: 'UTC',
      }).format(Date.parse(date))
    : '';
};

export const getInternationalFullDateDynamic = (
  date: string,
  weekday: any = 'short',
  year: any = 'numeric',
  hasTimezone: boolean = false
) => {
  return date
    ? new Intl.DateTimeFormat('en-US', {
        day: '2-digit',
        month: 'short',
        weekday,
        year,
        timeZone: hasTimezone ? 'America/New_York' : 'UTC',
      }).format(Date.parse(date))
    : '';
};

export const isToday = (d) => {
  const today = new Date();
  const theDate = new Date(parseInt(d, 10));

  return (
    today.getFullYear() === theDate.getFullYear() &&
    today.getMonth() === theDate.getMonth() &&
    today.getDate() === theDate.getDate()
  );
};
