import routes from '@config/routes';
import { serverFetcher } from '@instance';
import devConsole from '@utils/developer-console';

const getAffiliateData = async (token) => {
  try {
    return await serverFetcher(routes.getAffiliateData, {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (err) {
    devConsole(`getAffiliate - ${err}`);
    return { error: 'Something went wrong. Please try again later.' };
  }
};

const getAffiliateSalesData = async (token) => {
  try {
    return await serverFetcher(routes.getAffiliateSalesData, {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (err) {
    devConsole(`getAffiliateSalesData - ${err}`);
    return { error: 'Something went wrong. Please try again later.' };
  }
};

const getAffiliateSiteVisits = async (token) => {
  try {
    return await serverFetcher(routes.getAffiliateSiteVisits, {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (err) {
    devConsole(`getAffiliateSiteVisits - ${err}`);
    return { error: 'Something went wrong. Please try again later.' };
  }
};

const patchAffiliateData = async (token, affiliateInfo) => {
  try {
    return await serverFetcher(`${routes.affiliate}`, {
      method: 'PATCH',
      body: JSON.stringify({ ...affiliateInfo }),
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (err) {
    devConsole(`patchAffiliateData - ${err}`);
    return { error: 'Something went wrong. Please try again later.' };
  }
};

const postAffiliateVisit = async (url, affiliationId, isLoggedUser) => {
  try {
    return await serverFetcher(routes.affiliateSiteVisit, {
      method: 'POST',
      body: JSON.stringify({ url, affiliationId, isLoggedUser }),
    });
  } catch (err) {
    devConsole(`postAffiliateVisit - ${err}`);
    return { error: 'Something went wrong. Please try again later.' };
  }
};

const postAffiliateReferral = async (affiliationId, token) => {
  try {
    return await serverFetcher(routes.affiliateReferral, {
      method: 'POST',
      body: JSON.stringify({ affiliationId }),
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (err) {
    devConsole(`postAffiliateReferral - ${err}`);
    return { error: 'Something went wrong. Please try again later.' };
  }
};

const getAffiliateDiscountCodes = async (token) => {
  try {
    return await serverFetcher(routes.affiliateDiscountCodes, {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (err) {
    devConsole(`getAffiliateDiscountCodes - ${err}`);
    return { error: 'Something went wrong. Please try again later.' };
  }
};

const patchAffiliateReferral = async (orderId, newStatus, token) => {
  try {
    return await serverFetcher(`${routes.affiliateReferral}/${orderId}`, {
      method: 'PATCH',
      body: JSON.stringify({ status: newStatus }),
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (err) {
    devConsole(`patchAffiliateReferral - ${err}`);
    return { error: 'Something went wrong. Please try again later.' };
  }
};

export {
  getAffiliateData,
  getAffiliateDiscountCodes,
  getAffiliateSalesData,
  getAffiliateSiteVisits,
  patchAffiliateData,
  patchAffiliateReferral,
  postAffiliateReferral,
  postAffiliateVisit,
};
