import isDevMode from '@utils/dev-mode';

export default function devConsole(
  message?: any,
  ...optionalParams: any[]
): void {
  const checkType = (x: any): boolean => {
    return (
      typeof x === 'boolean' || typeof x === 'string' || typeof x === 'number'
    );
  };

  if (isDevMode) {
    if (
      checkType(message) &&
      Array.from(optionalParams).every((i) => checkType(i))
    ) {
      // eslint-disable-next-line no-console
      console.log(
        `%c${message} ${Array.from(optionalParams).join('')}`,
        'color: #bada55'
      );
    } else {
      // eslint-disable-next-line no-console
      console.log(message, ...optionalParams);
    }
  }
}
