import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';
import devConsole from '@utils/developer-console';

const defaultInit: RequestInit = {
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  method: 'POST',
};

const NEXTSITE = process.env.NEXTSITE_URL;
const SERVER = process.env.SERVER_URL;

function parseInstanceResponse(response: Response) {
  // response status for retrieving network errors
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    devConsole(
      `url: ${response.url}, status: ${response.status}, time: ${new Date().toLocaleTimeString()}`
    );
  }

  return response;
}

const instance = {
  server: async (
    url: string,
    config?: RequestInit,
    useStaging: boolean = false
  ) => {
    let fetchUrl: string = `${SERVER}${url}`;
    if (process.env.NODE_ENV === 'development' && useStaging) {
      fetchUrl = `https://api-staging.elitesportstours.com/api/v1${url}`;
    }
    return fetch(fetchUrl, {
      ...merge(cloneDeep(defaultInit), config),
    }).then(parseInstanceResponse);
  },

  api: async (url: string, config?: RequestInit) => {
    return fetch(`${NEXTSITE}/api${url}`, {
      ...merge(cloneDeep(defaultInit), config),
    }).then(parseInstanceResponse);
  },
};

async function safeParseJSON(response: Response) {
  // response status for retrieving network errors
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    devConsole(
      `url: ${response.url}, status: ${response.status}, time: ${new Date().toLocaleTimeString()}`
    );
  }

  try {
    const body = await response.text();
    return JSON.parse(body);
  } catch (err) {
    throw new Error(response.statusText);
  }
}

type RequestInitOptions<T = any> =
  | RequestInit
  | (Omit<RequestInit, 'body'> & { body?: T });

export const serverFetcher = async <T = Record<string, any>, K = any>(
  url: string,
  options?: RequestInitOptions<T>
): Promise<K> => {
  // eslint-disable-next-line prefer-const
  let { body, ...rest } = options || {};

  if (typeof body === 'object') {
    body = JSON.stringify(body);
  }

  return fetch(
    `${SERVER}${url}`,
    merge(cloneDeep(defaultInit), { body, ...rest })
  ).then(safeParseJSON);
};

export const multipartPost = async <T = Record<string, any>, K = any>(
  url: string,
  options?: RequestInitOptions<T>
): Promise<K> => {
  // eslint-disable-next-line prefer-const
  let { body, ...rest } = options || {};
  // @ts-ignore
  return fetch(`${SERVER}${url}`, { body, method: 'POST', ...rest }).then(
    safeParseJSON
  );
};

export const apiFetcher = async <T = Record<string, any>, K = any>(
  url: string,
  options?: RequestInitOptions<T>
): Promise<K> => {
  // eslint-disable-next-line prefer-const
  let { body, ...rest } = options || {};

  if (typeof body === 'object') {
    body = JSON.stringify(body);
  }

  return fetch(
    `${NEXTSITE}/api${url}`,
    merge(cloneDeep(defaultInit), { body, ...rest })
  ).then(safeParseJSON);
};

export default instance;
