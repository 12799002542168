const EVENTS_PAGE_SLUG = 'events';
const DASHBOARD_PAGE_SLUG = 'dashboard';

export const links = {
  home: '/',
  login: '/auth/login',
  logout: '/api/auth/logout',
  signup: '/auth/signup',
  resetPassword: '/auth/reset-password',
  error: '/booking-error',
  notFound: '/404',
  buy: '/buy',
  eventsPage: (link: string) => `/${EVENTS_PAGE_SLUG}${link}`,
  terms: '/terms-and-conditions',
  bookingConditions: '/booking-terms-and-conditions',
  privacy: '/privacy-policy',
  searchFlight: '/search-flight',
  searchHotel: '/v1/search-hotel',
  searchTickets: '/v1/search-tickets',
  checkout: '/checkout',
  favorites: '/dashboard/favorites',
  orders: '/dashboard/orders',
  discountCodes: '/dashboard/discount-codes',
  travelers: '/dashboard/travelers',
  profile: '/dashboard/profile',
  notifications: '/dashboard/notifications',
  emergencyNumbers: '/dashboard/emergency-numbers',
  affiliateGeneral: '/dashboard/affiliate/general',
  affiliatePerformance: '/dashboard/affiliate/performance',
  affiliateSales: '/dashboard/affiliate/sales',
  affiliatePromoCodes: '/dashboard/affiliate/promo-codes',
  adminReports: '/dashboard/admin/reports',
  adminOrders: '/dashboard/admin/orders',
  adminDiscountCodes: '/dashboard/admin/discount-codes',
  adminAgencies: '/dashboard/admin/agencies',
  adminUsers: '/dashboard/admin/users',
  adminBanners: '/dashboard/admin/banners',
  adminCacheLogs: '/dashboard/admin/cache-logs',
  adminPartners: '/dashboard/admin/partners',
  becomeAffiliate: '/become-affiliate',
  confirmation: (orderId: string | number) => `/confirmation/${orderId}`,
  dashboardPage: (link: string) => `/${DASHBOARD_PAGE_SLUG}/${link}`,
};

export const v2Links = {
  home: '/',
  login: '/auth/login',
  ambassadorSignup: '/auth/ambassador-signup',
  aboutUs: '/about-us',
  faq: '/faq',
  contactUs: '/contact-us',
  concertsLanding: '/concerts',

  confirmIdentity: '/auth/confirm-identity',

  enterPassword: '/auth/enter-password',
  completeAccount: '/auth/complete-account',
  // legal
  termsAndCondition: '/terms-and-conditions',
  privacyPolicy: '/privacy-policy',
  partnerTerms: '/partner-terms',
  // partners
  partnerSignup: '/auth/partner-signup',
  partnerSignupSlug: (slug) => `/auth/partner-signup/${slug}`,
  partnersLanding: '/sports-travel-agent-network',

  partnersLogin: '/auth/partner/login',
  partnerForgotPassword: '/auth/partner/forgot-password',

  buy: '/buy',
  buildPackage: '/build-package',
  bookingTerms: '/booking-terms-and-conditions',
  searchTickets: '/search-tickets',
  searchHotel: '/search-hotel',
  // settings
  orderDetails: (orderId: any) => `/dashboard/order-details/${orderId}`,
  orders: '/dashboard/orders',
  favorites: '/dashboard/favorites',
  rewards: '/dashboard/rewards',
  profile: '/dashboard/personal-info',
  travelers: '/dashboard/travelers',
  notifications: '/dashboard/notifications',

  affiliateGeneral: '/dashboard/affiliate/general',
  affiliatePerformance: '/dashboard/affiliate/performance',
  affiliateSales: '/dashboard/affiliate/sales',
  affiliatePromoCodes: '/dashboard/affiliate/promo-codes',

  partnersOverview: '/dashboard/partner/overview',
  partnersPerformance: '/dashboard/partner/performance',
  partnerSales: '/dashboard/partner/sales',
  partnerPromoCodes: '/dashboard/partner/promo-codes',
  partnerReports: '/dashboard/partner/report',
  partnerTeams: '/dashboard/partner/teams',

  adminUserDetails: (id: any) => `/dashboard/user-details/${id}`,
  adminDiscountCodeDetails: (id: any) =>
    `/dashboard/discount-code-details/${id}`,
  adminAgencies: '/dashboard/admin/agencies',

  adminReports: '/dashboard/admin/reports',
  adminOrders: '/dashboard/admin/orders',
  adminDiscountCodes: '/dashboard/admin/discount-codes',
  adminUsers: '/dashboard/admin/users',
  adminBanners: '/dashboard/admin/banners',
  adminCacheLogs: '/dashboard/admin/cache-logs',
  adminPartners: '/dashboard/admin/partners',

  dashboardPage: (link: string) => `/${DASHBOARD_PAGE_SLUG}/${link}`,
};
